/* eslint-disable @typescript-eslint/no-explicit-any */

export function getImgSrc(
	imageId?: string | null,
	fallback: 'camera' | 'event' | 'user' = 'user',
	resize: boolean = false,
	width?: number,
	height?: number,
	fit?: keyof any | string,
) {
	if (imageId && resize) {
		const query = new URLSearchParams()

		if (width) {
			query.set('w', width.toString())
		}
		if (height) {
			query.set('h', height.toString())
		}
		if (fit) {
			query.set('fit', fit.toString())
		}
		return `/api/file/resize/${imageId}?${query.toString()}`
	} else if (imageId) {
		return `/api/file/${imageId}`
	}
	return `/img/${fallback}.png`
}
