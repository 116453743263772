import { getInputProps } from '@conform-to/react'
import { useId, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../button/button'
import { ErrorList } from '../error-list/error-list'
import { Icon } from '../icon/icon'
import { getImgSrc } from '~/utils/image'
import { cn } from '~/utils/misc'

export const IMAGE_DEFAULT_MAX_SIZE = 1024 * 1024 * 10 // 10MB

export default function InputImage({
	imageId,
	alt,
	fieldConfig,
	className,
	maxSize = IMAGE_DEFAULT_MAX_SIZE,
	fallback = 'user',
	rounded = false,
}: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fieldConfig: any
	imageId?: string | null
	alt?: string | null
	className?: string
	maxSize?: number
	fallback?: 'camera' | 'event' | 'user'
	rounded?: boolean
}) {
	const [newImageSrc, setNewImageSrc] = useState<string | null>(null)
	const [deleteImg, setDeleteImg] = useState(false)
	const [error, setError] = useState(false)
	const { t } = useTranslation()
	const handleDeleteImg = () => {
		setDeleteImg(true)
		if (newImageSrc) {
			setNewImageSrc(null)
		}
	}
	const id = useId()
	const errorId = fieldConfig.errors?.length ? `${id}-error` : undefined
	return (
		<div className="mb-8 flex flex-col items-center justify-center">
			<div
				className={cn('relative h-52 w-52 flex-shrink-0', className)}
				style={{ viewTransitionName: 'image-expand' }}
			>
				<input name="deleteImg" value={deleteImg.toString()} readOnly hidden />
				<img
					src={
						deleteImg && !newImageSrc
							? getImgSrc('', fallback)
							: (newImageSrc ?? getImgSrc(imageId, fallback))
					}
					alt={alt ?? ''}
					className={cn(
						'h-full w-full object-cover',
						rounded ? 'rounded-full' : 'rounded-lg',
					)}
				/>
				<input
					{...getInputProps(fieldConfig, { type: 'file' })}
					accept="image/*"
					hidden
					className="sr-only"
					tabIndex={newImageSrc ? -1 : 0}
					onChange={e => {
						const file = e.currentTarget.files?.[0]
						if (file && file.size < maxSize) {
							setError(false)
							const reader = new FileReader()
							reader.onload = event => {
								setNewImageSrc(event.target?.result?.toString() ?? null)
							}
							reader.readAsDataURL(file)
						} else if (file && file.size > maxSize) {
							setError(true)
							e.currentTarget.value = ''
						} else if (deleteImg) {
							e.currentTarget.value = ''
						}
					}}
				/>

				{(imageId && !deleteImg) || newImageSrc ? (
					<Button
						variant="outline"
						className="absolute right-3 top-3 flex h-10 w-10 items-center justify-center !rounded-full !p-0 hover:cursor-pointer hover:!bg-background/80"
						type="button"
						onClick={handleDeleteImg}
					>
						<label>
							<Icon name="trash" className="h-4 w-4 hover:cursor-pointer" />
						</label>
					</Button>
				) : (
					<Button
						variant="outline"
						asChild
						className="!hover:bg-background/80 absolute right-3 top-3 flex h-10 w-10 items-center justify-center !rounded-full !p-0 hover:cursor-pointer"
					>
						<label htmlFor={fieldConfig.id}>
							<Icon name="camera" className="h-4 w-4 hover:cursor-pointer" />
						</label>
					</Button>
				)}
			</div>
			{error && (
				<ErrorList
					id={errorId}
					errors={[
						t('form_error_file_too_large', { size: maxSize / 1024 / 1024 }),
					]}
				/>
			)}
		</div>
	)
}
